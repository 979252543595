<template>
  <main class="main" data-view="404">
    <ui-title tag="6" class="ta-center">
      {{ cc.title }}
    </ui-title>
    <div class="img img--404">
      <img src="/img/img-404.png" alt="404">
    </div>
    <ui-btn :data="cc.btn[0]" type="2" class="btn-w-160 btn-w-sm-200 btn-s1 mx-auto"/>
  </main>
</template>
<script>
import uiTitle from '@/components/UI/Title'
import uiBtn from '@/components/UI/Btn'
export default {
  components: {
    uiTitle, uiBtn
  },
  computed: {
    cc () {
      return this.$t('pages.404')
    }
  },
  mounted () {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
}
</script>
